import type { company_address, Prisma } from "@prisma/client";
import { convertPrismaDecimalToNumber } from "./tools";

export function printAddressInformation(companyAddress: company_address) {
  return `${companyAddress.zip} ${companyAddress.place}`;
}

export function stringToNumber(numberString: string): number | null {
  let parsedNumber = parseFloat(numberString);
  if (isNaN(parsedNumber)) {
    return null;
  }
  return parsedNumber;
}

export function formatDecimalForInput(
  decimal: Prisma.Decimal | null | undefined
) {
  if (decimal) {
    let nr = convertPrismaDecimalToNumber(decimal);
    return formatNumberforInput(nr);
  }
  return "";
}

export function formatNumberforInput(number: number | null | undefined) {
  if (number) {
    return number.toString();
  }
  return "";
}
